import React from 'react'

import { colors, alpha, s } from '../style/style'

const MenuButton = ({ open, setOpen }) => {
  return (
    <div
      css={open ? [btnMain, active] : btnMain}
      onClick={() => setOpen(!open)}
      onKeyDown={() => setOpen(!open)}
      role="button"
      tabIndex={0}
      styling="link">
      <div className="top"></div>
      <div className="mid"></div>
      <div className="bot"></div>
    </div>
  )
}

const btnMain = {
  outline: 'none',
  position: 'fixed',
  zIndex: 1000,
  cursor: 'pointer',
  transition: 'all 0.3s ease-out',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  color: 'white',
  borderRadius: 24,
  [s.xs]: {
    top: 16,
    right: 16,
    width: 36,
    height: 36,
    backgroundColor: colors.background.darker.concat(alpha[60]),
  },
  [s.sm]: {
    top: 24,
    right: 24,
    width: 48,
    height: 48,
    backgroundColor: colors.background.darker.concat(alpha[20]),
  },
  '&:hover': {
    backgroundColor: colors.main.lightRed.concat(alpha[50]),
  },
  '.top, .mid, .bot': {
    backgroundColor: 'currentColor',
    height: 3,
    borderRadius: 4,
    margin: '2px 0',
    transition: 'inherit',
    width: 24,
    [s.xs]: { width: 20 },
    [s.sm]: { width: 24 },
  },
}

const active = {
  '.top': {
    transform: 'translate3d(0px, 7px, 0) rotate(-45deg)',
  },
  '.mid': {
    transform: 'scaleX(0.7)',
    opacity: 0,
  },
  '.bot': {
    transform: 'translate3d(0px, -7px, 0) rotate(45deg)',
  },
}

export default MenuButton
