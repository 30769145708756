import React from 'react'
import { Link } from 'gatsby'

// Components
import ContactSmall from './ContactSmall'
import SocialLinks from './SocialLinks'

// Styling
import { Grid } from '@material-ui/core'
import { colors, s } from '../style/style'

const { menuContent } = require('../content/content.js')

const Menu = () => {
  return (
    <Grid container css={style.main}>
      <Grid xs={12} sm={6} md={6} lg={4} item css={style.leftPart}>
        {menuContent.rest.map(page => (
          <Link
            to={`${page.link}`}
            key={`${page.name}${page.id}`}
            activeStyle={style.activeLink}>
            <h3>{page.name}</h3>
          </Link>
        ))}
      </Grid>
      <Grid xs={12} sm={6} md={6} lg={4} item css={style.midPart}>
        <Link
          to={`${menuContent.services.link}`}
          activeStyle={style.activeLink}>
          <h3>{menuContent.services.name}</h3>
        </Link>
        {menuContent.services.links.map(page => (
          <Link
            to={`${page.link}/#${page.name.toLowerCase().split(' ').join('-')}`}
            key={`${page.name}${page.id}`}>
            <h4>{page.name}</h4>
          </Link>
        ))}
      </Grid>
      <Grid xs={12} md={12} lg={4} item css={style.rightPart}>
        <ContactSmall />
        <SocialLinks />
      </Grid>
    </Grid>
  )
}

const style = {
  main: {
    overflowY: 'scroll',
    [s.xs]: { padding: '5vh 15vw 0' },
    [s.sm]: { padding: '5vh 10vw 0' },
    [s.lg]: { padding: '10vh 5vw 0' },
    [s.xl]: { padding: '10vh 10vw 0' },
    h3: {
      paddingBottom: 2 + 'rem',
      width: 'max-content',
      '&:hover': {
        color: colors.main.lightRed,
      },
      [s.xs]: { fontSize: 1.5 + 'rem' },
      [s.sm]: { fontSize: 1.5 + 'rem' },
      [s.lg]: { fontSize: 2.5 + 'rem' },
      [s.xl]: { fontSize: 3 + 'rem' },
    },
    h4: { fontWeight: 700 },
    h6: { fontWeight: 400 },
    'h4, h6': {
      [s.xs]: { fontSize: 0.875 + 'rem', paddingBottom: 1 + 'rem' },
      [s.sm]: { fontSize: 1 + 'rem', paddingBottom: 1 + 'rem' },
      [s.lg]: { fontSize: 1 + 'rem', paddingBottom: 1 + 'rem' },
      color: colors.main.lightRed,
      '&:hover': {
        color: 'white',
      },
    },
    'h3, h4, h6': {
      transition: 'color 0.15s ease-out',
    },
  },
  leftPart: {
    // height: '100%',
  },
  midPart: {
    // height: '100%',
    [s.xs]: { paddingBottom: 2 + 'rem' },
  },
  rightPart: {
    // height: '100%',
    [s.xs]: {
      paddingBottom: 4 + 'rem',
      h3: { fontSize: 1.25 + 'rem', paddingBottom: 1 + 'rem' },
    },
    [s.sm]: {
      paddingTop: 4 + 'rem',
      paddingBottom: 4 + 'rem',
      h3: { fontSize: 1.125 + 'rem' },
      p: { fontSize: 0.875 + 'rem' },
    },
    [s.md]: {
      h3: { fontSize: 1 + 'rem' },
    },
    [s.lg]: {
      paddingTop: 0,
      h3: { fontSize: 1.5 + 'rem' },
      p: { fontSize: 1 + 'rem' },
    },
    [s.xl]: {
      h3: { fontSize: 2 + 'rem' },
    },
  },
  activeLink: {
    textDecoration: `underline currentColor`,
  },
}

export default Menu
