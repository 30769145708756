import React from 'react'

import { Fade } from '@material-ui/core'
import { Collapse } from '@material-ui/core'

const Animate = ({
  appear,
  hide,
  fadeEnter = 1000,
  fadeExit = 400,
  collEnter = 1000,
  collExit = 1000,
  collDisabled = false,
  extraCSS,
  ...props
}) => {
  if (appear !== undefined)
    return (
      <Fade
        in={appear}
        timeout={{ enter: fadeEnter, exit: fadeExit }}
        css={extraCSS}>
        <Collapse
          in={collDisabled ? true : appear}
          timeout={{ enter: collEnter, exit: collExit }}
          css={extraCSS}>
          {props.children}
        </Collapse>
      </Fade>
    )
  else return props.children
}

export default Animate
