import React from 'react'
export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1920"
    height="338"
    viewBox="0 0 1920 338">
    <path d="M1920,338,0,0H1920Z" fill="#ff0a00" />
  </svg>
)
