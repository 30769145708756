import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

const ContactSmall = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            url
            address {
              lines
              location
            }
            contact {
              phone
              mail
            }
          }
        }
      }
    `
  )
  const $ = { ...site.siteMetadata }
  return (
    <div css={style.contactContainer} className="contactContainer">
      <Link to="/">
        <h3>{`${$.name}.pl`}</h3>
      </Link>
      <div css={style.detailsContainer}>
        <a href={$.address.location} target="_blank" rel="noreferrer">
          <h6 className="contactAddress">
            {$.address.lines.map((line, id) => (
              <p key={id}>{line}</p>
            ))}
          </h6>
        </a>
        <a href={`mailto:${$.contact.mail}`}>
          <h6>{$.contact.mail}</h6>
        </a>
        <a href={`tel:${$.contact.phone}`}>
          <h6>{$.contact.phone}</h6>
        </a>
      </div>
      {props.children}
    </div>
  )
}

const style = {
  contactContainer: {},
  detailsContainer: {
    paddingBottom: 1 + 'rem',
  },
}

export default ContactSmall
