import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

const CookiesAccept = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('cookies')) {
      setOpen(true)
    }
  }, [setOpen])

  return (
    <div css={[sContainer, !open && { display: 'none' }]}>
      <div>
        <p className="cookies-title">
          Przeglądając tą stronę zgadzasz się na używanie plików Cookies. Więcej
          na{' '}
          <Link to="/polityka-prywatnosci" css={sLink}>
            Polityka Prywatności
          </Link>
        </p>
      </div>
      <div
        css={sButton}
        onClick={() => {
          localStorage.setItem('cookies', true)
          setOpen(false)
        }}
        onKeyPress={() => {
          localStorage.setItem('cookies', true)
          setOpen(false)
        }}
        role="button"
        tabIndex={0}>
        OK
      </div>
    </div>
  )
}

const sContainer = {
  zIndex: 99999,
  width: '100vw',
  position: 'fixed',
  left: 0,
  bottom: 0,
  background: 'linear-gradient(45deg,rgba(20,20,20,0),rgba(20,20,20,1))',
  fontSize: '0.675rem',
  backdropFilter: 'blur(16px)',
  color: 'rgba(255,255,255,0.6)',
  padding: '0.5rem',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  textAlign: 'right',
  '@media screen and (max-width: 960px)': {
    background: 'linear-gradient(45deg,rgba(20,20,20,0.3),rgba(20,20,20,1))',
  },
}

const sButton = {
  fontSize: 13,
  transition: 'color 0.25s ease-out, background-color 0.25s ease-out',
  cursor: 'pointer',
  color: 'rgba(255,255,255,0.6)',
  padding: '0.25rem 0.75rem',
  backgroundColor: 'rgba(255,255,255,0.16)',
  fontWeight: 'bold',
  borderRadius: '0.5rem',
  marginLeft: '0.5rem',
  marginRight: '1rem',
  '&:hover': {
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
}

const sLink = {
  transition: 'color 0.25s ease-out',
  cursor: 'pointer',
  fontWeight: 'bold',
  '&:hover': {
    color: 'white',
  },
}

export default CookiesAccept
