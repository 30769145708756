import React from 'react'
import { Link } from 'gatsby'

import { s } from '../style/style'

import zadronowaniLogo from '../assets/zadronowani'

const Logo = () => {
  return (
    <div css={style}>
      <Link to="/">{zadronowaniLogo}</Link>
    </div>
  )
}

const style = {
  flexGrow: 1,
  height: '100%',
  alignSelf: 'flex-start',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& svg g path': {
    filter: 'drop-shadow(30px 10px 4px #000)',
  },
  [s.xs]: {
    justifyContent: 'center',
    svg: { height: 3 + 'rem', width: 'auto' },
  },
  [s.sm]: {
    justifyContent: 'center',
    paddingLeft: 1.5 + 'rem',
    svg: { height: 4 + 'rem', width: 'auto' },
  },
  [s.md]: {
    justifyContent: 'flex-start',
  },
}

export default Logo
