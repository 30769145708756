/*
  Pozostały content (metadata, dane kontaktowe, socials) w gatsby-config.js
*/

// PAGES Object defines itemList @ Header
const pages = {
  start: {
    headerId: 1,
    indexId: false,
    name: 'Start',
    link: '',
    title:
      'Zadronowani - Operatorzy dronów • Grupa pilotów bezzałogowych statków powietrznych',
    description:
      'Wynajmij pilota z dronem i kamerą. Nagramy niepowtarzalne ujęcia i wykonamy dla Ciebie produkcję video. Zdjęcia z drona i panoramy 360 to atrakcyjny format nowej reklamy.',
  },
  about: {
    headerId: 2,
    indexId: 2,
    name: 'Poznaj Nas',
    link: 'poznaj-nas',
    title: 'Poznaj nasze możliwości',
    description:
      'Nasi piloci specjalizują się w poszczególnych obszarach usług wykonywanych dronem. Wszyscy posiadają uprawnienia wydawane przez Urząd Lotnictwa Cywilnego. Nasze doświadczenie gwarantuje wysoką jakość i bezpieczeństwo.',
  },
  services: {
    headerId: 3,
    indexId: 3,
    name: 'Usługi',
    link: 'uslugi',
    title: 'Jeśli latać to z profesjonalistami',
    description:
      'Oferujemy usługi dedykowane dla poszczególnych sektorów. Zobacz naszą ofertę i skonsultuj swój pomysł. Sprawdź czy Zadronowani sprostają Twojemu wyzwaniu!',
  },
  portfolio: {
    headerId: 4,
    indexId: 4,
    name: 'Portfolio',
    link: 'portfolio',
    title: 'Produkcja Video, Zdjęcia lotnicze',
    description:
      'Portfolio naszej grupy. Włącz dźwięk i napawaj się widokiem znanych miejsc z nowej perspektywy.',
  },
  courses: {
    headerId: 5,
    indexId: 5,
    name: 'Szkolenia',
    link: 'szkolenia',
    title: 'Szkolenia pod okiem praktykujących pilotów',
    description:
      'Nasi piloci posiadają uprawnienia do prowadzenia szkoleń. Z nami nabędziesz potrzebnej wiedzy i umiejętności, aby uzyskać świadectwo kwalifikacji. Zostań pilotem BSP!',
  },
  tech: {
    headerId: 6,
    indexId: 6,
    name: 'Sprzęt',
    link: 'sprzet',
    title: 'Zobacz na czym latamy i nagrywamy',
    description:
      'Dobry sprzęt w połączeniu z umiejętnościami pozwala uzyskać wyjątkowe ujęcia. Dostosowujemy sprzęt do każdej produkcji i doradzamy w wyborze odpowiednich rozwiązań',
  },
  contact: {
    headerId: 7,
    indexId: 7,
    name: 'Kontakt',
    link: 'kontakt',
    title: 'Skontaktuj się z Nami',
    description:
      'Planujesz produkcję wideo? Teledysk, nagranie krajobrazu lub nieruchomości? Wyślij wiadomość lub wypełnij zamówienie. Przygotujemy dla Ciebie idealną ofertę.',
  },
  privacy: {
    headerId: false,
    indexId: false,
    name: 'Polityka Prywatność',
    link: 'polityka-prywatnosci',
    title: 'Polityka Prywatności',
  },
  service: {
    headerId: false,
    indexId: false,
    link: 'poszukiwania-dronem',
    name: 'Poszukiwania Dronem',
    title: 'Poszukiwania Dronem',
  },
  wynajem: {
    headerId: false,
    indexId: false,
    link: 'wynajem-drona',
    name: 'Wynajem Drona',
    title: 'Wynajem Drona',
  },
  event: {
    headerId: false,
    indexId: false,
    link: 'spacer-vr',
    name: 'Spacer Vr',
    title: 'Spacer Vr',
  },
  shooting: {
    headerId: false,
    indexId: false,
    link: 'dzien-zdjeciowy',
    name: 'Dzień Zdjęciowy',
    title: 'Dzień Zdjęciowy',
  },
  video: {
    headerId: false,
    indexId: false,
    link: 'nagranie-produkcja',
    name: 'Nagrania Video',
    title: 'Nagrania Video',
  },
  terms: {
    headerId: false,
    indexId: false,
    name: 'Regulamin Użytkowania',
    link: 'regulamin-uzytkowania',
  },
}

// MENUCONTENT defines LargeMenu & Footer content
const menuContent = {
  services: {
    name: 'Usługi',
    link: `/${pages.services.link}`,
    links: [
      { id: 1, name: pages.service.name, link: `/${pages.service.link}` },
      { id: 2, name: pages.wynajem.name, link: `/${pages.wynajem.link}` },
      { id: 3, name: pages.shooting.name, link: `/${pages.shooting.link}` },
      { id: 4, name: pages.event.name, link: `/${pages.event.link}` },
      { id: 5, name: pages.video.name, link: `/${pages.video.link}` },
      { id: 6, name: 'Kurs latania dronem', link: `/${pages.courses.link}` },
      { id: 7, name: 'Szkolenie BVLOS', link: `/${pages.courses.link}` },
      { id: 8, name: 'Nauka z operatorami', link: `/${pages.courses.link}` },

      {
        id: 11,
        name: pages.wynajem.name,
        link: `/${pages.wynajem.link}`,
      },
    ],
  },
  rest: [
    { id: 1, name: 'Start', link: `/${pages.start.link}` },
    { id: 2, name: 'Poznaj Nas', link: `/${pages.about.link}` },
    { id: 3, name: 'Portfolio', link: `/${pages.portfolio.link}` },
    { id: 4, name: 'Sprzęt', link: `/${pages.tech.link}` },
    { id: 5, name: 'Szkolenia', link: `/${pages.courses.link}` },
    { id: 6, name: 'Kontakt', link: `/${pages.contact.link}` },
  ],
  extras: [
    { id: 1, name: pages.terms.name, link: `/${pages.privacy.link}` },
    { id: 2, name: pages.privacy.name, link: `/${pages.privacy.link}` },
  ],
}

// FORM defines Context Form Values & ContactPage content
const form = {
  drones: {
    name: 'Drony',
    options: {
      drones: 'Wypożyczenie dronów',
      pilot: 'Wynajem pilota dronów',
      event: 'Dron na wydarzenie',
      vr: 'Spacer dronem VR',
    },
  },

  production: {
    name: 'Produkcja video',
    options: {
      pilot: 'Nagrania dronem',
      shooting: 'Dzień zdjęciowy',
      montage: 'Montaż i obróbka video',
    },
  },

  courses: {
    name: 'Szkolenia',
    options: {
      beginner: 'Kurs latania dronem',
      intermediate: 'Licencja BVLOS',
      advanced: 'Nauka z operatorami',
    },
  },
}

// TEXT defines Sections & Pages content
const text = {
  start: {
    headline: ['zobacz ', 'nową ', 'perspektywę '],
  },
  about: {
    headline: ['Poznajmy ', 'się ', 'Bliżej '],
    title: 'Profesjonalizm & Pasja',
    field: [
      'Zadronowani to grupa pilotów UAV. Łączy nas pasja do lotnictwa bezzałogowego i profesjonalne podejście do każdej usługi wykonywanej dronami. Wszyscy nasi piloci posiadają uprawnienia wydawane przez Urząd Lotnictwa Cywilnego oraz odpowiednie ubezpieczenie i przeszkolenie zgodne z zakresem ich uprawnień. Dzięki temu przygotowany przez nas materiał video i zdjęcia mogą być wykorzystywane komercyjnie.',
      'Rozwój technologii bezzałogowych statków powietrznych oraz doświadczenie naszych pilotów umożliwiło nam przygotowanie oferty dedykowanej dla konkretnych obszarów usług, zarówno dla firm jak i osób idywidualnych. Świadczymy usługi na terenie całej Polski. Każdego dnia nasi operatorzy obsługują następujące miasta:',
      'Gdańsk, Sopot, Gdynia, Warszawa, Kraków',
      'W pozostałych przypadkach możliwy jest dojazd pilota z dronem. Każdy lot dronem wykonywany przez Zadronowanych realizowany jest zgodnie z aktualnym prawem w oparciu o wytyczne Prezesa ULC. Loty w obszarach wymagających zgody są zgłaszane i koordynowane z Polską Agencją Żeglugi Powietrznej lub zarządzającym dana strefą.',
    ],
  },
  service: {
    headline: ['Poszukuj ', 'Dronem '],
    link: `/${pages.contact.link}`,
  },
  wynajem: {
    headline: ['Wynajem ', 'Drona '],
    link: `/${pages.contact.link}`,
  },
  event: {
    headline: ['Spacer ', 'Virtual ', 'Reality'],
    link: `/${pages.contact.link}`,
  },
  shooting: {
    headline: ['Dzień ', 'Zdjęciowy'],
    link: `/${pages.contact.link}`,
  },
  video: {
    headline: ['Nagranie ', 'Produkcja ', 'Video'],
    link: `/${pages.contact.link}`,
  },

  services: {
    headline: ['Oferty ', 'najwyższych ', 'lotów '],
    title: 'Usługi wykonywane dronami',
    field: [
      'Celem naszej grupy jest zwiększenie dostępności i popularyzacja usług wykonywanych za pomocą dronów. Każda nasza usługa dronowa dopasowywana jest do potrzeb klienta. Aby dać obraz tego jak dzisiaj wykorzystywane są nasze bezzałogowce przedstawiamy przykładowe propozycje usług. Jeśli jesteś kreatywny i w celu realizacji swojego pomysłu potrzebujesz doświadczonego pilota wraz ze sprzętem to właśnie w Zadronowanych możesz znaleźć takie osoby. Nagrywanie video i zdjęcia z drona to tylko podstawowe możliwości jakie zyskujesz przy usłudze wynajmu operatora z dronem.',
    ],
    scroll: 'przewiń',
    tiles: [
      {
        title: ['Wynajem', 'drona', 'wraz z', 'Pilotem'],
        link: 'wynajem-drona',
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.drones.name,
          //   option: form.drones.options.pilot,
          // },
        },
        icon: 'serviceHire',
        field: [
          'Wynajem operatora z dronem to usługa umożliwiająca wykonanie nagrań lub zdjęć dowolnego obiektu. To najlepsze rozwiązanie dla firm chcących udokumentować obiekty znajdujący się na wysokościach. Usługi tego typu wyceniane są w oparciu o lokalizacje i czas realizacji.',
        ],
      },
      {
        title: ['Nagranie', 'oraz', 'produkcja', 'Video'],
        link: 'nagranie-produkcja',
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.production.name,
          //   option: form.production.options.montage,
          // },
        },
        icon: 'serviceVideo',
        field: [
          'Ta usługa obejmuje zarówno nagranie materiału jak i profesjonalny montaż. Dopasujemy muzykę i odpowiednio przygotujemy nagranie w oparciu o konsultacje. Dzięki tej usłudze możesz stworzyć z nami atrakcyjną reklamę lub prezentacje dowolnego obiektu.',
        ],
      },
      {
        title: ['Dzień', 'zdjęciowy', 'kompleksowe', 'Pakiety'],
        link: 'dzien-zdjeciowy',
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.production.name,
          //   option: form.production.options.shooting,
          // },
        },
        icon: 'serviceShooting',
        field: [
          'Umożliwiamy wynajem operatora wraz ze sprzętem na cały dzień zdjęciowy. Przygotowaliśmy konkretne pakiety uwzględniające rożnego rodzaju sprzęt i poziom umiejętności operatorów.',
        ],
      },
      {
        title: ['Spacer', 'VR', 'drony na', 'Eventy'],
        link: 'spacer-vr',
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.drones.name,
          //   option: form.drones.options.event,
          // },
        },
        icon: 'serviceEvent',
        field: [
          'To usługa przygotowana w celu zapewnienia niesamowitych wrażeń podczas dowolnego eventu. Dzięki nam możesz przenieść sie 100 metrów nad ziemie i podziwiać panoramę miasta oraz poczuć jak to jest latać. Google VR sprawia ze poczujesz sie jakbyś siedział za sterami naszego drona. ',
        ],
      },
      {
        title: ['Poszukiwania', 'Dronem', 'dron do', 'poszukiwań'],
        link: 'poszukiwania-dronem',
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.production.name,
          //   option: form.production.options.shooting,
          // },
        },
        icon: 'serviceInvestigation',
        field: [
          'Wynajęcie pilota wraz z dronem umożliwiającym wsparcie z powietrza akcji poszukiwawczej. W tym celu wykorzystujemy drony z możliwością przybliżenia obrazu oraz gogle i duży ekran. Dzięki odpowiedniej strategii poszukiwań i wyznaczeniu konkretnego terenu do sprawdzenia zwiększamy szanse na odnalezienie osoby lub zwierzęcia. Najczęstszymi obiektami poszukiwań są zwierzęta, a konkretnie zagubione psy które zerwały się i w ataku paniki pobiegły zbyt daleko, by złapać trop powrotny. Jestęmy również w stanie wspomagać akcje poszukiwawcze ludzi.',
        ],
      },
      // {
      //   title: ['Usługi', 'inspekcji', 'dronami'],
      //   button: {
      //     title: 'ZAMÓW!',
      //     link: `/${pages.contact.link}`,
      //     // state: {
      //     //   service: form.drones.name,
      //     //   option: form.drones.options.event,
      //     // },
      //   },
      //   icon: 'serviceEvent',
      //   field: [
      //     'To usługa przygotowana w celu zapewnienia niesamowitych wrażeń podczas dowolnego eventu. Dzięki nam możesz przenieść sie 100 metrów nad ziemie i podziwiać panoramę miasta oraz poczuć jak to jest latać. Google VR sprawia ze poczujesz sie jakbyś siedział za sterami naszego drona. ',
      //   ],
      // },
    ],
  },
  portfolio: {
    headline: ['Spójrz ', 'z innej ', 'Perspektywy '],
    title: 'Produkcje z dronami',
    field: [
      'Poznaj możliwości jakie daje współpraca z Zadronowanymi. Stań się posiadaczem najlepszych zdjęć i zaskocz wszystkich niespotykanym dotąd przekazem. Zerknij na nasze portfolio - włącz dźwięk i napawaj się widokiem znanych miejsc z nowej perspektywy.',
    ],
  },
  tech: {
    headline: ['Sprawdź ', 'czym ', 'latamy '],
    title: 'Drony w ofercie',
    field: [
      'Nasza flota oferuje bardzo dużą rozpiętość jakościową oraz daje duże pole dla niemalże każdej produkcji video. Zobacz jakie drony są dostępne w naszej ofercie. Możesz sam wybrać jakim zestawem zrealizujemy dla Ciebie usługę.',
    ],
    drones: [
      {
        id: 1,
        name: 'DJI PHANTOM IV',
        image: 'drone_phantom',
        tier: 3,
        tierText: '',
        specs: {
          record: '2160p 30fps / bitrate 100 Mbps',
          photo: '5472×3648 pikseli',
          lens: 'Matryca 4K',
          pros: 'Świetne nagrania za dnia',
        },
      },
      {
        id: 2,
        name: 'DJI MAVIC 2 ZOOM',
        image: 'drone_zoom',
        tier: 3,
        tierText: '',
        specs: {
          record: '2160p 30fps / bitrate 100 Mbps',
          photo: '5472×3648 pikseli',
          lens: 'Matryca 4K',
          pros: 'Wyraziste zbliżenia kadrowe',
        },
      },
      {
        id: 3,
        name: 'DJI MAVIC 2 PRO',
        image: 'drone_mavic',
        tier: 2,
        tierText: 'Advanced',
        specs: {
          record: '2160p 30fps / bitrate 100 Mbps',
          photo: '5472×3648 pikseli',
          lens: 'Matryca 4K',
          pros: 'Idealna jakość nawet wieczorem',
        },
      },
      {
        id: 4,
        name: 'DJI INSPIRE 2 X5S',
        image: 'drone_inspire',
        tier: 1,
        tierText: 'Professional',
        specs: {
          record: '2160p 60fps / bitrate 100 Mbps',
          photo: '5280×3956 pikseli',
          lens: 'Matryca 5.2K',
          pros: 'Kinowa jakość / Najwyższa precyzja',
        },
      },
    ],
  },
  courses: {
    headline: ['Zostań ', 'operatorem ', 'dronów '],
    title: 'Szkolenia na drony',
    field: [
      'Nasi najbardziej doświadczeni operatorzy dzielą się swoim doświadczeniem i jako instruktorzy szkolą przyszłych pilotów bezzałogowych statków powietrznych. Dzięki szkoleniom możesz uzyskać uprawnienia i rozwijać umiejętności pilotażu dronów. Zapoznaj się z ofertą szkoleń i sięgnij po techniki rekomendowane przez doświadczonych operatorów.',
    ],
    options: [
      {
        title: ['Zdobądz', 'Uprawnienia'],
        description: [
          'Jesteś osobą, która chciałaby wkroczyć do świata dronów? Skorzystaj z naszych kursów i uzyskaj licencje pilota dronów! Nasi operatorzy prowadzą szkolenia do zdobycia kwalifikacji BVLOS. Więcej informacji znajdziesz na stronie KursyNaDrona.pl',
        ],
        pros: [
          'Wymagane zerowe doświadczenie',
          'Początkujący i pasjonaci dronów',
        ],
        button: {
          title: 'SPRAWDŹ!',
          link: `https://kursnadrona.pl`,
          external: true,
          // state: {
          //   service: form.courses.name,
          //   option: form.courses.options.beginner,
          // },
        },
      },
      {
        title: ['Podnoś swoje', 'Kwalifikacje'],
        description: [
          'Jeśli potrzebujesz przygotować profesjonalne video, dzięki praktyce zaplanowanej z naszymi operatorami, możesz stworzyć nagrania na wyższym poziomie! Elastyczne szkolenia - to Ty wybierasz miejsce i czas nagrania. Na szkoleniu dowiesz się więcej o ustawieniu kamery i dopasujesz odpowiednio swój kontroler do pracy w określonych warunkach.',
        ],
        pros: ['Wymagane uprawnienie', 'Szkolenie praktyczne'],
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.courses.name,
          //   option: form.courses.options.intermediate,
          // },
        },
      },
      {
        title: ['Lataj z', 'Pilotami'],
        description: [
          'Zgłębiaj najważniejsze tajniki i techniki profesjonalnych lotów. Naucz się wykonywać ujęcia zapierające dech w piersiach. Przetestuj lot z drugim pilotem przy wykorzystaniu dwóch kontrolerów. Poznaj jak wygląda produkcja z  prawdziwego zdarzenia, która pozwoli Ci  zwiększyć świadomość ruchu i wybrać  najlepsze ustawienia kamery.',
        ],
        pros: [
          'Wymagane uprawnienie',
          'Szkolenie praktyczne',
          'Zaawansowane ujęcia',
          'Loty w kooperacji',
        ],
        button: {
          title: 'ZAMÓW!',
          link: `/${pages.contact.link}`,
          // state: {
          //   service: form.courses.name,
          //   option: form.courses.options.advanced,
          // },
        },
      },
    ],
  },
  contact: {
    headline: ['Zawsze ', 'w kontakcie '],
    title: 'Skontaktuj się z Nami!',
    field: [
      'Planujesz produkcję wideo? Teledysk, nagranie krajobrazu, wydarzenia lub nieruchomości? Wyślij do nas wiadomość lub złóż zamówienie poprzez poniższe formularze zgłoszeniowe. Przygotujemy dla Ciebie idealną ofertę, dopasowaną do Twoich wymagań i możliwości.',
    ],
    form: {
      title: {
        messageForm: 'Napisz wiadomość',
        orderForm: 'Wypełnij zamówienia',
      },
      button: {
        message: 'WYŚLIJ!',
        order: 'ZAMÓW!',
      },
      name: 'NADAWCA',
      email: 'EMAIL',
      phone: 'TELEFON',
      service: 'USŁUGA',
      option: 'OPCJA',
      date: 'TERMIN REALIZACJI',
      place: 'MIEJSCE REALIZACJI',
      description: 'OPIS ZAMÓWIENIA...',
      message: 'WIADOMOŚĆ...',
      or: 'LUB',
    },
    setMetting: 'Umów się na spotkanie',
  },
}

module.exports = { pages, menuContent, text, form }
