import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styling
import '../style/default.css'
// import Loader from '../elements/Loader'
import { s, global } from '../style/style'

import Header from './header'
import Footer from './footer'
import Cookies from './cookies'

// Components
// const Header = lazy(() => import('./header'))
// const Footer = lazy(() => import('./footer'))

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false)

  const mainStyle = {
    [s.xs]: { marginTop: global.navbarHeight.mobile },
    [s.sm]: { marginTop: global.navbarHeight.desktop },
  }

  return (
    // <Suspense fallback={<Loader extraCSS={{ minHeight: 100 }} />}>
    <div>
      <Header open={open} setOpen={setOpen} />
      <main css={mainStyle}>{children}</main>
      <Footer />
      <Cookies />
    </div>
    // </Suspense>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
