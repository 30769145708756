import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import FacebookIcon from '@material-ui/icons/Facebook'
import YouTubeIcon from '@material-ui/icons/YouTube'
import InstagramIcon from '@material-ui/icons/Instagram'
import VimeoIcon from '../assets/vimeoIcon'

import { colors } from '../style/style'

const SocialLinks = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socials {
              facebook
              youtube
              instagram
              vimeo
            }
          }
        }
      }
    `
  )
  const $ = { ...site.siteMetadata.socials }
  return (
    <div css={style} className="socialLinks">
      <a href={$.facebook} target="_blank" rel="noreferrer">
        <FacebookIcon />
      </a>
      <a href={$.instagram} target="_blank" rel="noreferrer">
        <InstagramIcon />
      </a>
      <a href={$.youtube} target="_blank" rel="noreferrer">
        <YouTubeIcon />
      </a>
      <a href={$.vimeo} target="_blank" rel="noreferrer">
        {VimeoIcon}
      </a>
    </div>
  )
}

const style = {
  a: {
    width: 32,
    height: 32,
    paddingRight: 1 + 'rem',
    '&:hover': {
      svg: {
        color: 'white',
        opacity: 1,
      },
    },
    svg: {
      transition: 'opacity 0.15s ease-out, color 0.15s ease-out',
      color: colors.main.lightRed,
      width: 32,
      height: 'auto',
      opacity: 0.75,
    },
  },
}

export default SocialLinks
