import React from 'react'
import { Link } from 'gatsby'

import ContactSmall from '../elements/ContactSmall'
import SocialLinks from '../elements/SocialLinks'

// Styling
import { Grid, Hidden } from '@material-ui/core'
import { colors, s } from '../style/style'

import zadronowani from '../assets/Logo.svg'
import triangle1 from '../assets/triangle1'
import triangle2 from '../assets/triangle2'

const { menuContent } = require('../content/content.js')

const Footer = () => {
  return (
    <footer css={style.container}>
      <div css={style.triangle}>
        <Hidden lgUp>{triangle1}</Hidden>
        <Hidden mdDown>{triangle2}</Hidden>
      </div>
      <Grid
        container
        css={style.main}
        justifyContent="space-evenly"
        alignItems="flex-end">
        <Grid
          xs={12}
          sm={6}
          lg={3}
          xl={2}
          item
          css={style.serives}
          className="grid">
          <div className="part services">
            <Link to={`${menuContent.services.link}`}>
              <h5>{menuContent.services.name}</h5>
            </Link>
            {menuContent.services.links.map(page => (
              <Link
                to={`${page.link}/#${page.name
                  .toLowerCase()
                  .split(' ')
                  .join('-')}`}
                key={`${page.name}${page.id}`}>
                <p>{page.name}</p>
              </Link>
            ))}
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          lg={2}
          xl={1}
          item
          css={style.pages}
          className="grid">
          <div className="part pages">
            {menuContent.rest.map(page => (
              <Link to={`${page.link}`} key={`${page.name}${page.id}`}>
                <h5>{page.name}</h5>
              </Link>
            ))}
          </div>
        </Grid>
        <Grid xs={12} sm={6} lg={4} item className="grid">
          <div className="part info">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="row">
              <div className="logo">
                <img src={zadronowani} alt="logo" />
              </div>
              <Grid item xs={12} md={6} lg={4}>
                {menuContent.extras.map(page => (
                  <Link to={`${page.link}`} key={`${page.name}${page.id}`}>
                    <h5>{page.name}</h5>
                  </Link>
                ))}
              </Grid>
              <Grid item xs={12} md={6} lg={8} className="rightSide">
                <Link to="/">
                  <p>Developed by AppClever</p>
                </Link>
                <Link to="/">
                  <p>© Copyright 2020 RDB Group</p>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          lg={3}
          xl={2}
          item
          css={style.contact}
          className="grid">
          <div className="part contact">
            <ContactSmall />
            <SocialLinks />
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

const style = {
  main: {
    zIndex: 10,
    [s.xs]: { paddingBottom: 4 + 'rem' },
    [s.sm]: { paddingBottom: 4 + 'rem' },
    [s.lg]: { paddingBottom: 1 + 'rem' },
  },
  container: {
    position: 'relative',
    bottom: 0,
    left: 0,
    backgroundColor: colors.main.red,
    [s.xs]: { paddingTop: 2 + 'rem' },
    [s.sm]: { paddingTop: 2 + 'rem' },
    [s.md]: { paddingTop: 4 + 'rem' },
    [s.lg]: { paddingTop: 2 + 'rem' },
    [s.xl]: { paddingTop: 6 + 'rem' },
    '.part': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [s.xs]: { margin: '2rem 3rem 0', padding: '0 0 2rem' },
      [s.sm]: { margin: '3rem 3rem 0', padding: '0 0 3rem' },
      [s.lg]: { margin: '3rem 0 3rem', padding: '0 3rem 0' },
      h5: {
        paddingBottom: 1 + 'rem',
        fontSize: 1.125 + 'rem',
      },
      p: {
        fontSize: 0.875 + 'rem',
        paddingBottom: 0.5 + 'rem',
        color: colors.main.lightRed,
        fontWeight: 700,
      },
      '& a:last-child *': {
        marginBottom: 0,
        paddingBottom: 0,
      },
    },
    '.services, .pages': {
      [s.xs]: { borderBottom: '2px solid rgba(255, 255, 255, 0.4)' },
      [s.sm]: { borderBottom: '2px solid rgba(255, 255, 255, 0.4)' },
      [s.lg]: {
        borderRight: '2px solid rgba(255, 255, 255, 0.4)',
        borderBottom: 'none',
      },
    },
    '.pages': {
      [s.lg]: { border: 'none' },
      [s.xl]: { padding: 0 },
    },
    '.contact': {
      [s.xl]: { paddingRight: 0 },
      'h3, h6': { paddingBottom: 1 + 'rem' },
      h3: { fontSize: 1 + 'rem' },
      h6: {
        color: colors.main.lightRed,
        fontSize: 0.875 + 'rem',
      },
    },
    '.info': {
      [s.xs]: { borderBottom: '2px solid rgba(255, 255, 255, 0.4)' },
      [s.sm]: { borderBottom: 'none' },
      [s.lg]: {
        // paddingLeft: 0,
        borderRight: '2px solid rgba(255, 255, 255, 0.4)',
        borderLeft: '2px solid rgba(255, 255, 255, 0.4)',
      },
      h5: { fontSize: 0.875 + 'rem' },
      '.logo': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 4 + 'rem',
        [s.xs]: { marginBottom: 3 + 'rem' },
      },
      '.socialLinks': {
        paddingBottom: 2 + 'rem',
      },
      '.rightSide': {
        p: { fontSize: 0.75 + 'rem', paddingTop: 1 + 'rem', paddingBottom: 0 },
        h6: { paddingBottom: '1rem !important' },
        [s.md]: { textAlign: 'right' },
      },
    },
    'h3, h5, h6, p': { transition: 'color 0.15s ease-out' },
    'h3, h5': { '&:hover': { color: colors.main.lightRed } },
    'h6, .services p': { '&:hover': { color: 'white' } },
    '.contactAddress:hover': {
      p: { color: 'white' },
    },
  },
  triangle: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -2,
    left: 0,
    svg: {
      width: '100vw',
      height: 'auto',
      '& path': { fill: colors.main.background },
    },
  },
}

export default Footer
