export const colors = {
  background: {
    darker: '#18191A',
    dark: '#242526',
    light: '#3A3B3C',
    lighter: '#515354',
  },
  text: {
    title: '#E4E6EB',
    primary: '#D0D2D6',
    secondary: '#A0A3A7',
    subtitle: '#9096A6',
    blue: '#a3c4f5',
  },
  main: {
    tile: '#F2F2F2',
    red: '#AF091D',
    darkRed: '#7a1213',
    lightRed: '#FF8080',
    background: '#1e1e1e',
    dark: '#333333',
    light: '#474747',
    lighter: '#7F7F7F',
  },
  success: {
    text: '#4EFF4E',
    background: '#267326',
    background2: '#1D3C1D',
  },
  warning: {
    text: '#FFE24E',
    background: '#736626',
    background2: '#3C3B1D',
  },
  info: {
    text: '#4ea1ff',
    background: '#264a73',
    background2: '#1c2a3b',
  },
  error: {
    text: '#FF4E4E',
    background: '#732626',
    background2: '#3C1D1D',
  },
}

export const alpha = {
  100: 'FF',
  95: 'F2',
  90: 'E6',
  85: 'D9',
  80: 'CC',
  75: 'BF',
  70: 'B3',
  60: '99',
  50: '80',
  40: '66',
  30: '4D',
  25: '40',
  20: '33',
  10: '1A',
  5: '0D',
  0: '00',
}

export const xs = '@media screen and (max-width: 599px)'
export const ss = '@media screen and (max-width: 360px)'
export const sss = '@media screen and (max-width: 359px)'
export const sm = '@media screen and (min-width: 600px)'
export const md = '@media screen and (min-width: 960px)'
export const lg = '@media screen and (min-width: 1280px)'
export const xl = '@media screen and (min-width: 1920px)'

export const s = {
  xs,
  ss,
  sss,
  sm,
  md,
  lg,
  xl,
}

export const global = {
  navbarHeight: {
    mobile: 64,
    desktop: 96,
  },
  transitionColor: {
    transition: 'color 0.3s ease-out',
  },
}
