import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'

// Elements
import Logo from '../elements/Logo'
import MenuButton from '../elements/MenuButton'
import Menu from '../elements/Menu'

// Styling
import { colors, alpha, s, global } from '../style/style'
import Animate from '../components/animate'

const { pages } = require('../content/content.js')

const Header = ({ open, setOpen }) => {
  const refHeader = useRef(null)

  useEffect(() => {
    if (open && refHeader.current.scrollTop !== 0)
      refHeader.current.scrollTo({ top: 0, left: 0 })
  }, [open])

  const styleMenu = {
    willChange: 'opacity, background-color, height',
    transition: 'height 0.4s ease-in-out, background-color 0.6s ease-out',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    top: 0,
    left: 0,
    zIndex: 1000,
    position: 'fixed',
    overflowY: open ? 'scroll' : 'visible',
    backgroundColor: open
      ? colors.main.darkRed.concat(alpha[90])
      : colors.main.darkRed.concat(alpha[0]),
    width: '100vw',
    [s.xs]: {
      height: open ? '100vh' : global.navbarHeight.mobile,
      // overflowY: open ? 'scroll' : 'hidden',
    },
    [s.sm]: {
      height: open ? '100vh' : global.navbarHeight.desktop,
      // overflowY: open ? 'scroll' : 'hidden',
    },
    // [s.lg]: { overflowY: 'hidden' },
  }

  const renderMenuItems = () => (
    <nav css={open ? [style.menuList, { opacity: 0 }] : style.menuList}>
      {Object.values(pages)
        .filter(page => page.headerId)
        .sort((a, b) => a.headerId - b.headerId)
        .map(page => (
          <Link
            to={`/${page.link}`}
            key={`${page.name}${page.headerId}`}
            activeStyle={style.activePage}>
            <h3>{page.name}</h3>
          </Link>
        ))}
    </nav>
  )

  return (
    <>
      <div css={style.blur}></div>
      <header css={styleMenu} ref={refHeader}>
        <div css={style.headerContainer}>
          <Logo />
          {renderMenuItems()}
        </div>
        <Animate appear={open} collDisabled={true} fadeExit={200}>
          <Menu />
        </Animate>
      </header>
      <MenuButton setOpen={setOpen} open={open} />
    </>
  )
}

const style = {
  blur: {
    zIndex: 100,
    width: '100vw',
    [s.xs]: { height: global.navbarHeight.mobile },
    [s.sm]: { height: global.navbarHeight.desktop },
    backdropFilter: 'blur(10px)',
    position: 'fixed',
    top: 0,
  },
  headerContainer: {
    zindex: 4000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    [s.xs]: { height: global.navbarHeight.mobile },
    [s.sm]: { height: global.navbarHeight.desktop },
  },
  menuList: {
    transition: 'opacity 0.3s ease-out',
    display: 'none',
    alignItems: 'center',
    flexShrink: 1,
    minHeight: 48,
    padding: '0 1rem',
    margin: '0 6rem 0 2rem',
    backgroundColor: colors.main.background.concat(alpha[20]),
    borderRadius: 2 + 'rem',
    textAlign: 'center',
    a: {
      display: 'flex',
      padding: '0.75rem 1rem',
      fontWeight: 700,
      h3: {
        transition: 'border-color 0.15s ease-out',
        fontSize: 'inherit',
        borderBottom: '2px solid rgba(255, 255, 255, 0)',
      },
      color: 'white',
      '&:hover': {
        h3: { borderBottom: '2px solid currentColor' },
      },
    },
    [s.sm]: { fontSize: 0.875 + 'rem' },
    [s.md]: { display: 'flex' },
    [s.lg]: { fontSize: 1 + 'rem' },
  },
  menuListOpen: {
    overflow: 'hidden',
  },
  activePage: {
    color: colors.main.lightRed,
  },
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
